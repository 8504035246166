const translationEn = {
  common: {
    cancel: 'Cancel',
    delete: 'Delete',
    edit: 'Edit',
    orderNumber: 'Order number',
    orderDescription: 'Payment name',
    amount: 'Amount',
    copy: 'Copy',
    copied: 'Copied',
    savedCards: 'Saved cards',
    otherPayMethods: 'Payment methods',
    privacyPolicy:
      'By entering card data and clicking the pay button, you agree to the <extLink>privacy policy - PCI DSS Compliance Certificate</extLink>',
    cardNumber: 'Card number',
    cardHolder: 'Cardholder name',
    cvcHelp: '',
    saveCardQuestion: 'Save card?',
    itsSafe: 'It is safe',
    contactDetails: 'Contact details',
    agreeTerms: 'I agree to the terms of the <extLink>contract</extLink>',
    protectCard:
      'I want to protect my card, <extLink>more about the service</extLink>',
    email: 'Email',
    paymentProcessingTitle: 'Payment processing',
    paymentProcessingText: 'Payment is being processed.',
    pleaseWaitText: 'Please wait',
    orderPaid: 'Payment completed',
    wilBeRedirected:
      'You will be redirected to the store page in <darker>{{timer}} seconds</darker>',
    paymentDeclined: 'Payment failed',
    triesNumberIsExceed:
      'Please choose another payment method or wait to be redirected to the store page',
    paySum: 'Pay {{sum}}',
    redirectTitle: 'Redirecting',
    redirectMessage:
      "In a few seconds you will be redirected to the bank's page to confirm the payment",
    expiredDate: 'MM / YY',
    amountToPay: 'Amount to Pay',
    paymentDescription: 'Payment Description',
    payid: 'Pay ID',
    payidDescription:
      'Please make immediate payment via PayID using your internet banking app or portal. If you do not make payment immediately our system may automatically cancel your transfer. The funds must be transferred from a bank account held in your name.',
    payidSubmitText: 'I have completed the payment',
    payidProcessingTitle: 'Wait for confirmation',
    payidAttentionTitle: 'Important',
    duplicateTransactionAttemptingDescription:
      'In case of issues including duplicate transactions, please contact merchant’s support for quick resolution',
    payidProcessingDescription:
      'Wait for confirmation of payment receipt. If you have any difficulties with payment confirmation, please contact technical support.',
    payidSuccessTitle: 'The order has been paid',
    payidDeclinedTitle: 'Payment has been declined',
    payster: 'Top-Up Payster Balance by PayID',
    paysterBalanceReplenishedTitle: 'Balance has been replenished',
    paysterBalanceReplenishedDescription:
      'Now you can finalize your payment to Merchant platform',
    payoutMethods: 'Payout methods',
    transferFundsDescription:
      'Transfer funds to your bank account using {{method}}',
    payidType: 'PayID Type',
    bsbAccount: 'BSB Account',
    phoneNumber: 'Phone number',
    payoutByCardTitle: 'Payout by card',
    payoutProcessingTitle: 'Payout processing',
    payoutProcessingSubtitle:
      'The payout is in the process of being processed.',
    recurringPaymentConfirmationShortText:
      'By confirming your payment you authorize {{merchantName}} to regularly charge your payment card on the amount indicated in this form on a regular basis',
    recurringPaymentConfirmationFullText:
      'By confirming your payment you authorize {{merchantName}} to regularly charge your payment card on the amount indicated in this form on a regular basis, and shall remain in effect until you request for the cancellation or termination.\nYou likewise certify that you are the authorized user of the payment card that shall be submitted through this form. As long as the transactions correspond to the terms and conditions indicated in this authorization, you shall not raise disputes against {{merchantName}}.',
    payoutSuccessTitle: 'Payout has been made',
    payoutDeclinedTitle: 'Payout has been declined',
    payoutBy: 'Payout by {{method}}',
    useBSBTransferOnYouBankAccount: 'Use BSB transfer on your bank app.',
    useThisAccountForThisTransactionOnly:
      'Use this account for this transaction only',
    noticeOnlyPayOnce: '<darker>Notice:</darker>\xa0You can only pay once',
    copySpecificOrderAmount:
      'Please be sure to copy the specific order amount.',
    overpaidAmountsNotRefunded: 'Overpaid amounts will not be refunded',
    copyPaymentsDetailsText:
      '<ghost>Copy payment details - <darker>{{method}}</darker></ghost>',
    goToBankingAppText:
      '<ghost>Go to your banking application and make a transfer using the specified payment details</ghost>',
    clickPaymentButtonText:
      '<ghost>Click "<darker>{{submitButtonText}}</darker>"</ghost>',
    ibanText: 'IBAN',
    secureTransactionLaunchedText: 'Secure transaction launched',
    operationWillBeAbortedIfNotConfirmWithinText:
      'If you do not confirm the transfer within <darker>7 minutes</darker>, the operation will be automatically aborted',
    pressButtonAfterPaymentText:
      'Please press the button only when the payment has been made.',
    paysterPaymentAlertText:
      '<ghost>Please make the payment via PayID<br/>All the fields must be only copied and pasted to your app<br/><br/><darker>Notice:</darker> Please paste the exact indicated amount in your app, otherwise the payment will be declined.</ghost>',
  },
  buttons: {
    details: 'Order details',
    hide: 'Hide',
    tryAnotherMethod: 'Another payment method',
    tryAnotherCard: 'Repeat payment by card',
    backToMerchant: 'Back to the store',
    selectMethod: 'Try again',
    pay: 'Pay',
    back: 'Back',
    reviewPayout: 'Review Payout',
    confirmPayout: 'Confirm Payout',
    transfer: 'Transfer',
    continue: 'Continue',
  },
  validate: {
    required: 'This field is required',
    email: 'Please enter valid email address',
    luhnCheckFailed: 'Bad card number',
    cardNumberMinLength: '16 digits required',
    cardHolderMustHaveOnlyLatinLetters: 'Use capital latin letters',
    cardHolderNameTooLong: 'Cardholder name is too long',
    cardHolderMustHaveTwoWords:
      'Cardholder name must be two separate words with space',
    expMonthFormat: 'Invalid month format',
    expYearFormat: 'Invalid year format',
    cardExpired: 'Card is expired',
    cvcFormat: '3 digits required',
    invalidPhoneNumber:
      'Please enter valid phone number (e.g. +12\u20113456789)',
    invalidBSB: 'Please enter valid BSB',
    invalidAccountNumber: 'Please enter valid Account number',
    emailCharacterLimit: 'Email must be at most {{limit}} characters',
    phoneCharacterDownLimit: 'Phone must be at least {{limit}} characters',
    phoneCharacterUpLimit: 'Phone must be at most {{limit}} characters',
    fieldCannotEmpty: 'The field cannot be empty',
    firstNameTooLong: 'The first name is too long',
    lastNameTooLong: 'The last name is too long',
    emailMustBeValid: 'The email must be valid',
    phoneMustContainBetween:
      'The phone number must be valid and contain between {{min}} and {{max}} characters',
    accountNumberMustContainBetween:
      'The account number must be valid and contain between {{min}} and {{max}} characters',
    accountHolderMustContainLeast:
      'The account holder name must be valid and contain at least {{min}} characters',
    walletIdMustContainAndDigitsOnly:
      'The Wallet Id must contain {{length}} characters, digits only. Leading zero is not allowed',
    accountNumberMustContainFromTo:
      'The Account number must contain from {{from}} to {{to}} characters',
    invalidIbanFormat:
      'Invalid IBAN. Please ensure it follows the correct format.',
    swiftCodeMustContainFromTo:
      'The SWIFT code must contain from {{from}} to {{to}} characters',
    invalidSwiftCodeFormat:
      'Invalid SWIFT code. Please ensure it follows the correct format.',
    fieldExpects2wordsSeparatedSpace:
      'This field expects 2 words separated by a space',
    documentNumberMustContainAndDigitsOnly:
      'The document number must contain {{length}} characters, digits only',
    accountHolderMustBeValid: 'The account holder name must be valid',
    recipientFirstNameMustBeValid: 'Recipient’s first name must be valid',
    recipientLastNameMustBeValid: 'Recipient’s last name must be valid',
    recipientDateOfBirthMustBeValid: 'Recipient’s date of birth must be valid',
    recipientEmailMustBeValid: 'Recipient’s e-mail must be valid',
    ibanMustBeInSEPAZone: 'IBAN must be in SEPA-zone',
    invalidAccountHolder:
      'The account holder name must be valid and contain at least 2 words separated by space',
  },
  errorPage: {
    reloadPage: 'Reload page',
    paymentTimeExpired: 'Time to payment has run out.',
    payoutTimeExpired: 'Time to payout has run out.',
    anotherTry: 'Something got wrong. Take another try.',
    somethingGotWrong: 'Something got wrong.',
    somethingWentWrong: 'Something went wrong',
    oops: 'Oops',
    unspecifiedError:
      'An unspecified error has occurred, please contact the shop for get more details',
  },
  paymentMethods: {
    cards: 'Cards',
    otherCard: 'Payment by other card',
    netbanking: 'Net Banking',
    upi: 'UPI',
    bankDeposit: 'Bank Deposit',
    bankTransfer: 'Bank Transfer',
    p2p: 'Peer to Peer (P2P)',
    qrPayment: 'QR Payment',
    wallets: 'Wallets (Alipay)',
    lbt: 'LBT',
    poli: 'POLi',
    payid: 'Pay by PayID',
    payster: 'Pay by Payster',
    interac: 'Interac',
    hayhay: 'HayHay',
    pep: 'PEP',
    paycell: 'PayCell',
    paybol: 'Paybol',
    banktransferp2p: 'Bank Transfer P2P',
    paparapool: 'PaparaPool',
    papara: 'Papara',
    payfix: 'PayFix',
    communitybank: 'Community Bank',
    instantqr: 'Instant QR',
    onlinebankingbtv: 'Online banking BTV',
    instantPay: 'Instant Pay',
    p2pgejpy: 'Peer to Peer (P2P)',
    p2ptransferplatcore: 'P2P Transfer',
    p2pgecny: 'Peer to Peer (P2P)',
    unionpaygecny: 'UnionPay',
    rapipagoprars: 'RapiPago',
    khipuprars: 'Khipu',
    ibanTransferP2P: 'IBAN Transfer (P2P)',
    sbpP2P: 'СБП (P2P)',
    m10P2P: 'm10 (P2P)',
    cardTransferP2P: 'Card transfer (P2P)',
    topUpBalanceWithCrypto: 'Top up balance with crypto',
    ideal: 'iDeal',
    bankcontract: 'Bankcontract',
    blik: 'Blik',
    zenPay: 'ZenPay',
    sepaQr: 'SEPA QR',
    sepaInstant: 'SEPA Instant',
    sepaBankTransfer: 'SEPA bank transfer',
    sbpQr: 'SBP (QR)',
    applePay: 'Apple Pay',
  },
  payoutMethods: {
    withdrawByCards: 'Withdraw by card',
    payoutByPayid: 'Payout by PayID',
    eCashout: 'eCashout',
    interacTransfer: 'Interac\xa0e\u2011Transfer',
    payid: 'PayID',
    card: 'card',
    bankCard: 'bank card',
    payfix: 'PayFix',
    communitybank: 'Community Bank',
    sepa: 'SEPA',
    sepaInstant: 'SEPA Instant',
  },
  fields: {
    emailAddress: 'Email Address',
    proceedToPayment: 'Proceed to payment',
    amountToPay: 'Amount to Pay',
    paysterBalance: 'Your Payster balance',
    phone: 'Phone',
    bsb: 'BSB',
    accountNumber: 'Account number',
    transitNumber: 'Transit number',
    financialInstitutionNumber: 'Financial institutional number (FIN)',
    amountToWithdraw: 'Amount to Withdraw',
    availableAmountToTransfer: 'Available amount to Transfer',
    firstName: 'First name',
    lastName: 'Last name',
    email: 'E-mail',
    accountHolderName: 'Account holder name',
    walletId: 'Wallet ID',
    phoneNumber: 'Phone number',
    accountNumberIban: 'Account number (IBAN)',
    swiftCode: 'SWIFT Code',
    turkishIdNumber: 'Document number (Turkish ID Number)',
    recipientsNameSurname: "Recipient's Name and Surname",
    recipientsFirstName: 'Recipient’s first name',
    recipientsLastName: 'Recipient’s last name',
    recipientsDateOfBirth: 'Recipient’s date of birth',
    recipientsEmail: 'Recipient’s e-mail',
    recipientsCountryOfResidence: 'Recipient’s country of residence',
    recipientsIBAN: 'Recipient’s IBAN',
    accountHolderNameAndLastName: 'Account holder Name and Last name',
    accountHolder: 'Account holder',
  },
  payster: {
    enterEmail: 'Enter Your Email Address',
    transferFundsToPaysterAccount: 'Transfer funds\nto your Payster account',
    transferFundsToPaysterAccountSubtitle:
      'To withdraw funds from Merchant platform, you need to top-up Payster account and make a payout',
  },
} as const;

export default translationEn;
